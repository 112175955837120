import Vue from 'vue';
import VueRouter from 'vue-router';
import routerExtend from '@/common/router/router-extend';

Vue.use(VueRouter);
let themes = localStorage.getItem('themes') || process.env.VUE_APP_BUILD_THEMES;
const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/login/',
        name: 'login',
        alias: '/login-jump/',
        component: () => import('../home/views/login-jump.vue'),
        meta: {
            title: '登录',
            requireLogin: false
        }
    },
    {
        path: '/login',
        name: 'classic',
        alias:['/login-classic/'],
        component: () => import('../' + themes + '/login/views/login.vue'),
        meta: {
            title: '登录',
            requireLogin: false
        }
    },
    {
        path: '/login',
        name: 'default',
        alias:['/login-default/'],
        component: () => import('../' + themes + '/login/views/login2.vue'),
        meta: {
            title: '登录',
            requireLogin: false
        }
    },
    {
        path: '/login/auth/authLogin',
        name: 'auth',
        component: () => import('../' + themes + '/login/views/auth.vue'),
        meta: {
            title: '单点登录验证',
            requireLogin: false
        }
    },
    {
        path: '/login/forget-password',
        name: 'forgetPassword',
        component: () => import('../' + themes + '/login/views/forget-password.vue'),
        meta: {
            title: '忘记密码',
            requireLogin: false
        }
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../home/views/index.vue'),
        children: [
            // {
            //     path: '/',
            //     redirect() {
            //         return '/home';
            //     }
            // },
            {
                path: '/home',
                name: 'home',
                component: () => import('../' + themes + '/index/views/index.vue'),
                meta: {
                    title: ''
                }
            }
        ]
    },
    {
        path: '/cert',
        name: 'Cert',
        component: () => import('../home/views/cert.vue'),
        meta: {
            title: '证书登录',
            requireLogin: false
        },
        children: []
    },
    {
        path: '/license',
        name: 'SystemLicense',
        component: () => import('../' + themes + '/login/views/license.vue'),
        meta: {
            title: '系统注册码',
            requireLogin: false
        }
    },
];

const router = new VueRouter({
    routes
});

routerExtend.extend(router,"index");

export default router;
