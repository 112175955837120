
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from '@/common/store';
import axios from '@/common/http';
import '@/common/common';
import i18n from '@/common/i18n';
import api from './http';
import { removeAxiosHeader, setAxiosHeaders } from '@/common/http/axios';
import {commonHandle} from '@/common/plugins/common-handle';

// 模拟数据接口
if (process.env.NODE_ENV === 'mock') {
    import('@/common/mock')
        .then(work)
        .catch(console.log);
} else {
    work();
}
async function work() {
    store.commit('common/setTokenUrl', api.common.getToken);
    await initToken();
    await commonHandle(api,router,store,i18n,'index');
    new Vue({
        el: '#app',
        render: h => h(App),
        template: '<App/>',
        router,
        store,
        i18n
    }).$mount('#app');
}

async function initToken() {
    let url = store.getters['common/getTokenUrl'];
    let requestToken = await getToken(url);
    if(requestToken){
        removeAxiosHeader('requestToken');
        // 设置headers
        setAxiosHeaders({ requestToken });
    }
}

function getToken(url) {
    return axios.get(url, {}, { __expires__: 'true'},false ).then(res => {
        if(res && res.data && res.data.token){
            let { data } = res;
            return Promise.resolve(data.token);
        }else{
            return null;
        }

    });
}