<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isRouterAlive: true
        };
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        }
    },
    provide() {
        return {
            reload: this.reload
        };
    }
};
</script>
<style lang="scss" scoped>
#app {
    text-align: center;
}
</style>
